@import '../../common/styles/_colors.scss';
@import '../../common/styles/_mixin.scss';

.hero_img {
  object-fit: contain;
  object-position: top;
  margin-top: 5rem;
  margin-left: 5vw;
  max-width: 90vw;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  &::-webkit-media-controls {
    display: none !important;
  }

  background: transparent
    radial-gradient(closest-side at 50% 50%, #003842 0%, #000000 100%) 0% 0%
    no-repeat padding-box;
}

.hero_img_text_container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  z-index: 298;
}

.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  z-index: 299;

  &.cover {
    height: 100%;
  }
}

.no_background {
  background: unset !important;
}

.as-relative {
  position: relative;
}

.pull-top {
  margin-top: -5.6rem;
}

.hero-message {
  position: absolute;
  color: white;
  display: flex;
  width: 100vw;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  bottom: 2rem;
  z-index: 301;

  .big-hero-message {
    font-size: 32px;
    text-align: center;
    margin-bottom: 10px;
  }

  .small-hero-message {
    font-size: 18px;
    font-weight: 100;
    margin-bottom: 1rem;
    letter-spacing: -1px;
    font-weight: bold;
  }
}

.container {
  overflow: hidden;
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.gesture {
  position: absolute;
  opacity: 0.4;
  bottom: 39vh;
  left: calc(50vw - 1rem);
  width: 2rem;
  animation: linear infinite alternate;
  animation-name: run;
  animation-duration: 0.5s;
}

@keyframes run {
  0% {
    left: calc(50vw - 2rem);
  }
  50% {
    left: calc(50vw);
  }
  100% {
    left: calc(50vw - 2rem);
  }
}

@media screen and (min-width: $tabletBreakPoint) {
  .hero_img {
    object-fit: cover;
    max-width: 100vw;
    min-height: calc(100vh - 5.625rem);
    height: 100%;
    width: 100%;
    margin: 0;
    &::-webkit-media-controls {
      display: none !important;
    }
    background: transparent
      radial-gradient(closest-side at 50% 50%, #003842 0%, #000000 100%) 0% 0%
      no-repeat padding-box;
  }
  .pull-right {
    text-align: right;
  }

  .container {
    width: 100vw;
    height: 100vh;
  }
  .hero-message {
    position: absolute;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    padding-left: 93px;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    max-width: 33rem;

    .big-hero-message {
      font-size: 2.875rem;
      font-weight: 200;
      text-align: left;

      .and-sign {
        font-weight: 100;
      }
    }

    .small-hero-message {
      font-size: 1.8rem;
      font-weight: 100;
      margin-bottom: 1rem;
      letter-spacing: 2.5px;
      font-weight: bold;
    }
  }

  .gesture {
    bottom: 10vh;
    left: calc(50vw - 2rem);
    width: 4rem;
  }
}
