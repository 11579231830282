@import '../../common/styles/_colors.scss';

.burger-container {
  margin-left: auto;
}

.mobile_languages_selector {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #003842 0%, #000000 100%) 0% 0%
    no-repeat padding-box;
  display: none;

  &.display {
    display: unset;
  }

  .header {
    display: flex;

    .chevron_left {
      width: 30px;
      transform: scale(1.5);
    }

    .header_title {
      font-size: 18px;
      font-weight: 400;
      color: $color-white;
      flex: 1;
      margin: 0;
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .language_option {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    margin-top: -1px;
    color: white;
    font-size: 18px;
    font-weight: 300;

    &.active {
      font-weight: 400;
      color: $primary;
    }
  }
}

.mobile_menu_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  padding-top: 80px;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #003842 0%, #000000 100%) 0% 0%
    no-repeat padding-box;
  display: none;

  &.display {
    display: unset;
  }

  .mobile_menu_option {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    border-top: 1px solid #333;
    color: white;
    font-size: 18px;
  }

  .register_option {
    a {
      color: rgb(23, 169, 191);
    }
  }

  .mobile_language_container {
    display: flex;
    align-items: center;
    cursor: pointer;

    .img {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }

    .language_label {
      padding-left: 0.5rem;
      padding-right: 1rem;
    }

    .arrow-down {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;

      border-top: 4px solid #fff;
      transition: border-top 0.15s ease-in-out;
    }
  }
}

#header {
  &.fixed {
    position: fixed;
    top: 0px;
    z-index: 301;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    width: 100%;
    background: transparent linear-gradient(0, #00000000 0%, #000000 100%) 0% 0%
      no-repeat padding-box;
  }
}

.header {
  margin: 1rem 0;
  transition: all 0.3s ease-in;

  .row {
    z-index: 301;
  }

  ul {
    list-style-type: none;
    display: flex;
    color: $text-color;
    margin: 0;
    li {
      cursor: pointer;
      transition: all 0.3s ease-in;

      &.pricing {
        display: none;
      }

      &.blog {
        display: none;
      }

      &:hover {
        color: $primary;
      }
      &.asButton {
        border: 0;
      }
    }

    .with-space {
      padding: 0 5px;
    }
  }
}

@media screen and (min-width: $ipad-width) {
  .header {
    margin: 1.5rem 0;
    .logoText {
      font-size: 2rem;
    }

    ul {
      // align-items: center;
      li {
        &.pricing {
          display: block;
        }

        &.blog {
          display: block;
        }

        &.asButton {
          margin-left: 0.7rem;
        }
      }

      .with-space {
        padding: 5px 1rem;
      }

      .with-border {
        border: 2px solid $second;
      }
    }
  }
}
