@import '../common/styles/colors';

.langauges-container {
  position: relative;

  .languages-button {
    display: flex;
    align-items: center;
    padding: 0 14px;
    cursor: pointer;

    &:hover {
      .arrow-down {
        border-top: 4px solid $primary;
      }
    }

    .img {
      margin-right: 6px;
      width: 20px;
      height: 20px;

      &.dark {
        filter: invert(0.78);
      }
    }

    .arrow-down {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;

      border-top: 4px solid #fff;
      transition: border-top 0.15s ease-in-out;

      &.dark {
        border-top-color: #000;
      }
    }
  }

  .languages-dropdown {
    position: absolute;
    top: 30px;
    right: 0;
    background: red;
    cursor: default;
    padding: 16px 0;
    width: 200px;
    background: $color-black;
    display: none;

    &.show {
      display: unset;
    }

    .item {
      padding: 12px 20px;
      color: $color-text-light;
      font-weight: 300;
      cursor: pointer;

      &:hover {
        color: $color-white;
        font-weight: 400;
      }

      &.active {
        background: #1a1a1a;
        color: $color-white;
        font-weight: 400;
      }
    }
  }
}
