@import "../../../common/styles/colors";
@import "../../pricing-variables";

.pricing__standard-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
  max-width: $features-width;
}

@media screen and (max-width: $tabletBreakPoint) {
  .pricing__standard-features {
    flex-direction: column;
    align-content: center;
  }
}