@import "../../common/styles/colors";
@import "../pricing-variables";

.pricing__subscriptions {
  display: flex;
  margin-bottom: 30px;
  gap: 20px;
  max-width: $features-width;
}

@media screen and (max-width: $tabletBreakPoint) {
  .pricing__subscriptions {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}
