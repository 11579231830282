$primary: #0096af;
$second: #31b0c4;

$gray-one: #d1d1d1;
$gray-two: #707070;
$gray-three: #999999;
$gray-four: #b9b9b9;
$gray-five: #ededed;
$gray-six: #e6e6e6;

$body: #f7f7f7;

$dark-gray: #333333;
$darker-gray: #212121;

$color-white: #fff;
$color-black: #000;
$header: $color-white;

$text-color: $dark-gray;
$color-text-lightest: $gray-two;
$color-text-light: $gray-three;
$color-text-dark: #333;
$color-text-mid-light: #a7a9ab;
$color-text-lightest: #c1c1c1;

$border-color: $gray-one;

$ipad-width: 768px;
$ipad-height: 800px;
$mobile-width-num: 768;
$tablet-width-num: 1025;

$mobileBreakPoint: $mobile-width-num + px;
$tabletBreakPoint: $tablet-width-num + px;
$mBookAirWidth: 1440px;

$unit: 16px;
$gutter-width: 1rem;
$outer-margin: 1.7rem;
$gutter-compensation: $gutter-width * 0.5 * -1;
$half-gutter-width: $gutter-width * 0.5;

/* font metrics */
$fm-emSquare: 1;
$fm-capitalHeight: 0.68;
$fm-descender: 0.54;
$fm-ascender: 1.1;
$fm-linegap: 0;

$font-weight-bold: 600;
$font-weight-normal: 400;
$font-weight-light: 300;
$font-weight-thin: 100;

$min_width: 320px;
$max_width: 1920px;

:export {
  mobileWidth: $mobile-width-num;
  tabletWidth: $tablet-width-num;
}

:root {
    --accent-color: #0096af;
    --bg-color: #262626;
    --text-color: #e6e6e6;
    --border-color-second: #666666;
    --border-color: #333;
    --bar-color: #1a1a1a;
    --disable-color: #333
}
