@import "../../common/styles/colors";
@import "../pricing-variables";

.pricing__faqs {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $faq-width;

  &-heading {
    width: 100%;
    font-size: 20px;
    font-weight: $font-weight-normal;
  }

  &-heading:not(:first-child) {
    margin-top: 50px;
  }
}
