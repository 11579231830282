@import "../../../common/styles/colors";

.pricing__outsource-feature {
  width: 30%;
  margin-bottom: 4rem;

  &-title, &-subtitle {
    margin: 0 0 10px 0;
  }

  &-title {
    font-size: 20px;
    font-weight: $font-weight-normal;
  }

  &-subtitle, &-unit, &-ready-to {
    font-weight: $font-weight-light;
  }

  &-price {
    font-size: 20px;
    color: #1FC5DB;

    &-container{
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }

  &-unit, &-subtitle {
    color: #CCCCCC;
  }

  &-ready-to {
    color: $color-text-light;
    font-size: 14px;
    line-height: 27px;
  }

  &-image {
    width: 100%;
  }
}

@media screen and (max-width: 1700px) {
  .pricing__outsource-feature {
    width: 45%;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: $tabletBreakPoint) {
  .pricing__outsource-feature {
    width: 100%;
    margin-bottom: 3rem;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba($gray-three, 0.2);
  }
}