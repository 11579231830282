@import '../../common/styles/_colors.scss';

.footer {
  &.dark {
    background: $darker-gray;
  }

  &.for-mobile {
    background: #1a1a1a;
  }

  &.transparent {
    background: transparent;
  }
  color: $color-white;
  padding: 2rem 0;

  .row {
    margin-left: $outer-margin + $gutter-compensation;
    margin-right: $outer-margin + $gutter-compensation;
    flex-direction: column-reverse;
  }

  .patient-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 0;
  }

  .patient-action {
    font-size: 1.5rem;
  }

  .patient-info {
    text-align: center;
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 300;
  }

  .footer-links {
    ul {
      list-style-type: none;
      line-height: 2rem;
      font-size: 1rem;
      font-weight: 300;
    }
  }

  &.minimal {
    padding: 0;

    .footer-links {
      ul {
        margin: 0;
        padding: 0;
        text-align: center;

        li {
          display: inline-block;
          padding: 0 0.2rem;
        }
      }
    }
  }
}

@media screen and (min-width: $ipad-width) {
  .footer {
    .row {
      flex-direction: row;
      align-items: center;
      margin: 0 auto;
      max-width: 1280px;
      width: 80vw;

      .navigation {
        flex: 2;
      }

      .patient-area {
        height: 12rem;
        padding: 0;
        flex: 4;
        align-items: flex-end;
        justify-content: flex-start;
      }

      .patient-info {
        padding-top: 0.45rem;
        p {
          margin: 0;
          line-height: 1.9rem;
          text-align: right;
        }
      }
    }
  }
}

@media screen and (max-height: $ipad-height) {
  .footer {
    .footer-links {
      ul {
        margin: 0;
        padding: 0;
        text-align: center;

        li {
          display: inline-block;
          padding: 0 0.2rem;
        }
      }
    }
  }
}
