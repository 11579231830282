@import "../../../common/styles/colors";
@import "../../pricing-variables";

.collapsible {
  &__container {
    width: 100%;
    border-bottom: 1px solid $color-text-light;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  &__header {
    width: 100%;
    color: #CCCCCC;
    background-color: transparent;
    text-align: left;
    padding: 0;
    border: 0;
    cursor: pointer;
    height: 2rem;


    &-icon {
      position: absolute;
      right: 0;
      line-height: 32px;
      pointer-events: none;
    }

    &-container {
      position: relative;
      display: flex;
    }
  }

  &__header:hover {
    color: $color-white;

    + .collapsible__header-icon {
      color: $color-white;
    }
  }

  &__content, &__header-icon {
    color: $color-text-light;
  }

  &__content {
    font-weight: $font-weight-normal;
    margin-bottom: 0;
    line-height: 1.5rem;

    &-container {
      max-height: 0;
      overflow: hidden;
      transition: max-height $transition-speed;
    }
  }
}