@import "../../common/styles/colors";
@import "../pricing-variables";

.modal {
  &__backdrop {
    z-index: 302;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba($color-black, 0.4);
    visibility: hidden;
    opacity: 0;

    &-open {
      opacity: 1;
      visibility: visible;
      display: flex;
      transition: opacity $transition-speed, visibility $transition-speed;
    }
  }

  &__content {
    position: relative;
    background-color: #1A1A1A;
    margin: auto;
    padding: 0;
  }

  &__close {
    color: $color-text-light;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 10px;
  }

  &__close:hover {
    color: $color-white;
    transition: color $transition-speed;
  }
}