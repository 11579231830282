@import "../../../common/styles/colors";
@import "../../pricing-variables";

.pricing__outsource-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 70%;
  padding-left: 3rem;

  &-blog-link {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a:link {
      color: $secondary-color;
    }

    p {
      color: #CCCCCC;
      font-weight: $font-weight-light;
    }
  }
}

@media screen and (max-width: $tabletBreakPoint) {
  .pricing__outsource-features {
    flex-direction: column;
    align-content: center;
    width: 100%;
    padding-left: 0;

    &-blog-link {
      width: 100%;
    }
  }
}