@import "../common/styles/_colors.scss";

$border-color: #003742;
#download {
    background: black;
    position: relative;
}

.download-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: transparent radial-gradient(circle at 75% 20%, #003842 0%, #000000 60%) 0% 0% no-repeat padding-box;
    top: 0;
}

.download-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    padding-top: 2rem;
    position: relative;

    .box {
        display: flex;
        width: 90vw;
        height: 60vh;
        border-top: 1px solid $dark-gray;
        border-radius: 0;
        padding: 4rem 1rem 1rem 1rem;
        margin: 0;
        flex-direction: column;
        align-items: center;
        position: relative;
        justify-content: center;

        &.no-border {
            border-color: transparent;
        }

        &.centered {
            justify-content: center;
        }
    }

    .box-title {
        font-size: 3rem;
        font-weight: 200;
        color: $color-white;
    }

    .box-action {
        text-decoration: none;
        font-size: 1.2rem;
        color: $color-white;
        border: 1px solid $primary;
        padding: 0.5rem 1rem;
        cursor: pointer;
        border-radius: 10px;
        margin-top: 1rem;
        min-width: 10rem;
        text-align: center;
        z-index: 2;
        transition: all 0.2s ease-in-out;

        &:hover {
            color: $primary;
            border-color: $color-white;
        }
    }

    .box-image {
        height: 60vh;
        position: absolute;
        bottom: 0;
    }

    .box-image-placeholder {
        height: 60vh;
    }

    .download-title {
        font-size: 2.2rem;
        font-weight: 700;
        color: $color-white;
    }

    .download-description {
        font-size: 1.1rem;
        font-weight: 300;
        color: $color-white;
        line-height: 1.6rem;
        margin-top: 2rem;
    }
}

.browsers-list {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 6rem;

    .browser {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 5rem;
    }

    .browser-link {
        text-decoration: none;
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }

    .browser-image {
        width: 2rem;
    }

    .browser-name {
        font-size: 1rem;
        color: $color-white;
        padding: 1rem;
    }
}

@media screen and (min-width: $ipad-width) {
    #download {
        background: transparent;
    }
    .download-overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: transparent radial-gradient(circle at 75% 30%, #003842 0%, #000000 40%) 0% 0% no-repeat padding-box;
        top: 0;
        z-index: -1;
    }
    .download-container {
        padding: 4rem 5rem 2rem 5rem;

        .box {
            width: 25vw;
            height: calc(25vw / 1.14);
            border: 1px solid $border-color;
            border-radius: 24px;
            margin: 2rem;
            padding: 0;
            justify-content: unset;
            min-width: 25rem;
            min-height: calc(25rem / 1.14);
            max-width: 36.5rem;
            max-height: calc(36.5rem / 1.14);

            &.no-border {
                border-color: transparent;
            }

            &.centered {
                justify-content: center;
            }
        }

        .box-title {
            font-size: 3rem;
            font-weight: 200;
            color: $color-white;
            padding-top: 1rem;
        }

        .box-action {
            text-decoration: none;
            font-size: 1.2rem;
            color: $color-white;
            border: 1px solid $border-color;
            padding: 0.5rem 1rem;
            cursor: pointer;
            border-radius: 10px;
            margin-top: 1rem;
            min-width: 9rem;
            text-align: center;
            z-index: 2;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: $color-white;
                border-color: $primary;
            }
        }

        .box-image {
            height: auto;
            width: 24vw;
            position: absolute;
            bottom: 0;
            min-width: 23rem;
            max-width: 34rem;
        }

        .download-title {
            font-size: 2.5rem;
        }
    }

    .browsers-list {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 5rem;

        .browser {
            width: 7rem;
        }

        .browser-link {
            text-decoration: none;
            transition: transform 0.2s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }

        .browser-image {
            width: 2.5rem;
        }

        .browser-name {
            font-size: 1rem;
            color: $color-white;
            padding: 1rem;
        }
    }
}
