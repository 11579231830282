@import "./_colors.scss";
@import "./_normalize.scss";
@import "./_mixin.scss";

/* desired capital height and line-height */
$capital-height: 2.3;
$line-height: 1;

/* compute needed values */
$lineheightNormal: $fm-ascender + $fm-descender + $fm-linegap;
$computedFontSize: calc($capital-height / $fm-capitalHeight);

$distanceBottom: $fm-descender;
$distanceTop: $fm-ascender - $fm-capitalHeight;
$contentArea: $lineheightNormal * $computedFontSize;
$valign: ($distanceBottom - $distanceTop) * $computedFontSize;
$computedLineheight: ($line-height * $capital-height) - $valign;

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    overflow-x: hidden !important;
    color: #333;

    /* width */
    &::-webkit-scrollbar {
        width: 0.2rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #000;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #666;
        border-radius: 3px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #666;
    }
}

.container-fluid,
.container {
    margin-right: auto;
    margin-left: auto;
}

.row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: $outer-margin;
    margin-left: $outer-margin;
    position: relative;
}

.hidden-overflow {
    overflow: hidden;
    margin-right: -1.5rem;
}

.no-margins {
    margin-left: $outer-margin * -1;
    margin-right: $outer-margin * -1;
}

.middle {
    align-items: center;
}

.margin-top {
    margin-top: 3.5rem;
}

.margin-bottom {
    margin-bottom: 1.5rem;
}

.black {
    background: #000;
}

.gray {
    background: $darker-gray;
}

.left,
.right {
    box-sizing: border-box;
    flex: 0 0 auto;
    flex-grow: 1;
}

.left {
    flex-basis: 20%;
    max-width: 20%;
    display: flex;
}

.right {
    flex-basis: 80%;
    max-width: 80%;
    justify-content: flex-end;
}

.pullRight {
    float: right;
}

.figure {
    flex: 2;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    img {
        position: relative;
        width: 90vw;
        max-width: 1000px;
        margin-left: -5vw;
        top: -2rem;
    }
}

.title {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include fluid-type($min_width, $max_width, 65px, 240px, 0.8);
    letter-spacing: -2px;
    font-weight: 900;

    .line {
        display: flex;
    }

    .tradeMark {
        display: none;
        font-size: 1.2vw;
        line-height: 2.9rem;
        border: 2px solid #333;
        height: 2rem;
        border-radius: 50%;
    }
}

.subtitle {
    font-weight: 100;
    @include fluid-type($min_width, $max_width, 20px, 36px, 0.8);
    margin-left: calc(0.1rem + 0.5vw);
    margin-bottom: 0.5rem;
}

//Demo

.demo {
    object-fit: contain;
    width: 100%;
    max-height: 100vw;
}

.demo-title {
    @include fluid-type($min_width, $max_width, 20px, 36px, 0.8);
    font-weight: 100;
    padding-bottom: 1rem;
}

//End Demo

.as-flex {
    display: flex;

    &.full-width {
        width: 100%;
    }

    &.col {
        flex-direction: column;
    }

    &.right {
        align-items: flex-end;
    }

    &.left {
        align-items: flex-start;
    }

    &.top {
        justify-content: flex-start;
    }

    &.bottom {
        justify-content: flex-end;
    }

    &.center {
        align-items: center;
    }

    &.middle {
        justify-content: center;
    }
}

.space-left {
    margin-left: 0.5rem;
}

// Links
.as-link {
    color: $color-white;
    cursor: pointer;
    text-decoration: none;

    transition: all 0.2s ease-in;

    &.underline {
        text-decoration: underline;
    }

    &.primary {
        color: $primary;

        &:hover {
            color: lighten($primary, 4%);
        }
    }

    &.dark {
        color: $dark-gray;

        &:hover {
            color: lighten($primary, 4%);
        }
    }

    &.active {
        color: $primary;

        &.dark {
            color: $primary;
        }
    }

    &.hero {
        width: 100%;
        max-width: 30rem;
        z-index: 302;
    }

    &:hover {
        color: $primary;
    }

    &.full-width {
        width: 100%;
    }
}

// End Links

.actionText {
    font-size: calc(1.2rem + 2.1vw);
    font-weight: 900;
    // /* set capital height to equal font-size */
    // font-size: $computedFontSize * 1px;

    // /* set computed line-height */
    // line-height: $computedLineheight * 1px;
}

.actionExtraText {
    font-weight: 100;
    font-size: calc(0.9rem + 0.5vw);
    display: flex;
    align-items: flex-end;
}

//Button
.button {
    display: flex;
    max-width: 20rem;
    justify-content: center;
    padding: 1rem;
    font-size: 1.3rem;
    cursor: pointer;
    transition: all 0.3s ease-in;
    margin: 0 auto;
    font-weight: 400;
    text-align: center;

    &.primary {
        background: $primary;
        color: $color-white;

        &.outline {
            border: 1px solid $primary;
            color: $primary;
            background: transparent;
        }

        &:hover {
            background: darken($primary, 4%);
        }
    }

    &.reduced {
        max-width: 14rem;
        padding: 0.7rem;
    }

    &.main {
        max-width: 16rem;
        padding: 0.7rem;
        font-size: 20px;
    }

    &.hero {
        font-size: 1.4rem;
        margin: 0 auto;
        max-width: 10rem;
    }

    &.mobile-hero {
        font-size: 1.4rem;
        margin: 0 auto;
        max-width: 18rem;
    }

    &.start {
        max-width: 16rem;
        padding: 0.7rem;
        font-size: 20px;
        color: $dark-gray;
        border: 1px solid $primary;

        &:hover {
            background: #f7f7f7;
        }
    }
}

// Layout
.centered {
    max-width: 740px;
    margin: 0 auto;
}

// End layout

// Typography
.page-title {
    display: flex;
    justify-content: center;
    flex: 1;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include fluid-type($min_width, $max_width, 32px, 64px, 0.8);
}

.long-text {
    color: #95989a;

    font-weight: 300;
    line-height: 1.4rem;
    justify-content: center;
    width: 90vw;
    max-width: 80rem;
    padding-bottom: 1rem;
    text-align: justify;

    &.full-width {
        width: 100%;
        margin: 0 auto;
    }

    p {
        margin-top: 0;
    }

    .data-row {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .data-title {
        font-weight: 400;
    }

    .data-legal {
        font-style: italic;
    }
}

// End Typography

//Carousel
.slick-slider {
    width: 100%;
    min-height: 30rem;
}

.slick-dots {
    bottom: 10px;
    z-index: 300;

    li {
        button {
            &::before {
                color: #656565;
                font-size: 1rem;
                opacity: 1;
            }
        }

        &.slick-active {
            button {
                &::before {
                    color: $color-white;
                    opacity: 1;
                }
            }
        }
    }
}

//End Carousel

// Home
#home {
    background: $color-black;
}

// End Home

@media screen and (min-width: $ipad-width) {
    .no-margins {
        margin-left: $outer-margin * -1;
        margin-right: $outer-margin * -1;
    }

    .figure {
        img {
            position: absolute;
            right: -15rem;
            top: unset;
            width: 55vw;
        }
    }

    .title {
        letter-spacing: -5px;
    }

    .margin-top {
        margin-top: 120px;
    }

    .subtitle {
        margin-bottom: 1rem;
    }

    .actionText {
        /* set capital height to equal font-size */
        font-size: $computedFontSize * 1rem;

        /* set computed line-height */
        line-height: $computedLineheight * 1rem;
    }

    .actionExtraText {
        font-size: calc(1rem + 0.5vw);
        display: inline-block;
    }

    .page-title {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .centered {
        max-width: 90vw;
        width: 90vw;
        margin: 0 auto;
    }

    .button {
        width: 100%;
    }

    .slick-slider {
        min-height: 20rem;
    }

    .connect {
        min-height: 40rem;
        max-height: 32vw;
    }

    .hidden-overflow {
        overflow: unset;
    }
}

@media screen and (min-width: $tabletBreakPoint) {
    .as-link {
        &.hero {
            width: 30rem;
            z-index: 302;
        }
    }

    .button {
        &.hero {
            margin: 0;
        }
    }
}
