@import "../../common/styles/_colors.scss";
@import "../../common/styles/_mixin.scss";

.patient-ea {
    .easy-access {
        padding: 1rem 0 1.5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        align-items: center;
        overflow: hidden;

        &.ea-content {
            max-height: 41rem;
        }

        .shift-left {
            margin-right: 0;
            text-align: center;
            .actionExtraText {
                font-size: calc(1.1rem + 0.5vw);
                justify-content: center;
                line-height: calc(2.6rem + 0.5vw);
            }
        }

        .shift-right {
            margin-left: 0;

            .actionExtraText {
                font-size: calc(1.1rem + 0.5vw);
                justify-content: center;
                line-height: calc(2.6rem + 0.5vw);
            }
        }

        .ea-main-image {
            width: unset;
            height: 55vh;
            margin-left: 140px;
            background: transparent radial-gradient(closest-side at 50% 50%, #003842 0%, #000000 100%) 0% 0% no-repeat padding-box;

            @media (max-width: 768px) {
                margin: 0;
                width: 100%;
                height: auto;
            }
        }
    }

    .ea-message-container {
        display: flex;
        flex-direction: column;
        max-width: 19rem;
        color: $color-white;
        font-size: 1rem;
        font-weight: 300;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem;
        margin: 0 auto;

        .ea-message-title {
            font-size: 1.625rem;
            font-weight: 700;

            .ea-message-title-icon {
                height: 1.6rem !important;
                width: auto !important;
                margin: 0 !important;
                margin-top: -0.4rem !important;
                margin-right: 0.6rem !important;
            }

            .ea-message-title-logo {
                height: 2.6rem !important;
                width: auto !important;
                margin: 0 !important;
                margin-right: 0.6rem !important;
                margin-bottom: -0.6rem !important;
            }
        }

        .ea-message-body {
            padding-bottom: 0;

            p {
                margin: 1rem 0;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
        .ea-message-action {
            display: none;
        }
    }

    .ea-menu {
        display: flex;
        justify-content: center;
        font-size: 1.25rem;
        border-bottom: 1px solid #707070;
        background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
        opacity: 1;

        .ea-menu-item {
            color: $color-white;
            opacity: 0.5;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            transition: all 0.2s ease-in;
            padding-bottom: 1rem;
            border-bottom: 3px solid transparent;

            &:not(:first-of-type) {
                margin-left: 6.4rem;
            }

            &:hover {
                opacity: 0.8;
            }

            &[data-active="true"] {
                opacity: 1;
                border-bottom: 3px solid $primary;
            }

            .ea-menu-item-icon {
                font-size: 3rem;
                height: 3rem;
                margin-bottom: 0.75rem;
            }
        }
    }

    .ea-carousel {
        display: flex;
        flex-direction: row;
        width: calc(400vw);
        margin-left: 0;
        transition: all 0.2s ease-in;

        &.two {
            margin-left: -100vw;
        }

        &.three {
            margin-left: -200vw;
        }

        &.four {
            margin-left: -300vw;
        }
    }

    .ea-full-width {
        display: block;
    }

    .ea-row {
        box-sizing: border-box;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-right: 1.7rem;
        padding-left: 1.7rem;
        position: relative;
        border-top: 1px solid #333333;

        &.ea-last {
            border-bottom: 1px solid #333333;
            padding-bottom: 2rem;
        }

        @media (max-width: 768px) {
            padding: 0;
        }
    }

    @media screen and (min-width: $ipad-width) {
        .easy-access {
            padding: 0;

            .shift-left {
                margin-right: 28rem;
            }

            .shift-right {
                margin-left: 30rem;
                margin-top: 3rem;
            }

            .ea-main-image {
                max-width: 90vw;
                max-height: 60vh;
                height: unset;
                width: unset;
                margin: unset;
            }
        }

        .ea-menu {
            padding-top: 5rem;
        }

        .ea-message-container {
            padding-bottom: 2rem;
            align-items: flex-start;
            font-size: 1.5rem;

            .ea-message-title {
                font-size: 1.8rem;
                text-align: left;

                .ea-message-title-icon {
                    height: 1.6rem !important;
                    width: auto !important;
                    margin: 0 !important;
                    margin-top: -0.4rem !important;
                    margin-right: 0.6rem !important;
                }

                .ea-message-title-logo {
                    height: 2.6rem !important;
                    width: auto !important;
                    margin: 0 !important;
                    margin-right: 0.6rem !important;
                    margin-bottom: -0.6rem !important;
                }
            }

            .ea-message-body {
                text-align: left;
                p {
                    margin: 1.5rem 0;
                    font-size: 1.25rem;
                    line-height: 2rem;
                }
            }

            .ea-message-action {
                display: flex;
                justify-content: flex-start;
            }
        }
        .ea-row {
            flex-direction: row;
            padding: unset;
            border-top: none;

            &.ea-last {
                border-bottom: none;
                padding-bottom: 2rem;
            }

            @media (max-width: 768px) {
                margin: 0;
            }
        }

        .ea-header {
            display: none;
        }

        .ea-full-width {
            width: 100vw;
            margin-top: 2rem;
        }
    }

    .asc-apps {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.patient-ea-margin-left {
    margin-left: auto !important;

    @media (min-width: $ipad-width) {
        margin-left: -20rem !important;
    }
}
