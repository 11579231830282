@import "../../../common/styles/colors";
@import "../../pricing-variables";

.pricing-button {
  padding: 8px 0;
  width: 100%;
  max-width: 250px;
  border-radius: 6px;
  color: $color-white;
  cursor: pointer;
  box-sizing: border-box;
  transition: border $transition-speed;

  &__wide {
    max-width: 100%;
  }

  &__primary {
    border: 1px solid $color-black;
    background-color: $accent-color;
  }

  &__outlined {
    border: 1px solid $accent-color;
    background-color: $color-black;
  }
}

.pricing-button:hover {
  border: 1px solid $color-white;
}