@import "../../../common/styles/colors";
@import "../../pricing-variables";

.pricing__outsource {
  padding: 4rem 0;
  position: relative;
  display: flex;
  background-color: #1A1A1A;
  box-shadow: 50vw 0 #1A1A1A, -50vw 0 #1A1A1A;
  max-width: $outsource-width;
  margin-bottom: 4rem;

  &-aside, &-aside-title {
    font-weight: $font-weight-light;
  }

  &-aside {
    width: 25%;

    &-title {
      font-size: 36px;
    }

    &-description-light {
      margin: 3rem 0 2rem 0;
      color: $color-text-light;
    }
  }
}

@media screen and (max-width: $tabletBreakPoint) {
  .pricing__outsource {
    flex-direction: column;
    align-items: center;

    &-aside {
      width: 100%;
      margin-bottom: 3rem;

      &-description, &-description-light {
        display: none;
      }
    }
  }
}
