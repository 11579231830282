@import "../../common/styles/colors";
@import "../pricing-variables";

.pricing__interval {
  position: relative;
  text-align: center;
  //margin: 20px 0 40px;
  display: flex;
  justify-content: center;
  font-weight: $font-weight-light;

  &-save {
    margin-left: 5px;
    color: $gray-three;
  }

  &-radio {
    #pricing__annual-subscription-interval,
    #pricing__monthly-subscription-interval {
      position: absolute;
      opacity: 0;

      + .pricing__interval-radio-label {
        cursor: pointer;

        &:before {
          content: '';
          background-color: $color-black;
          border-radius: 100%;
          border: 2px solid $gray-six;
          display: inline-block;
          width: 1rem;
          height: 1rem;
          position: relative;
          top: -0.2rem;
          margin-right: 8px;
          vertical-align: top;
          cursor: pointer;
          transition: all $transition-speed;
        }
      }

      &:checked {
        + .pricing__interval-radio-label {
          &:before {
            background-color: $accent-color;
            border-color: $accent-color;
            box-shadow: inset 0 0 0 3px $color-black;
          }
        }
      }
    }
  }

  &-container {
    position: relative;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 10px 0;

    &-sticky {
      width: 100%;
      background-color: $color-black;
      position: fixed;
      top: 62px;
    }
  }

  &-inputs {
    display: flex;
    gap: 20px;
    position: relative;
    align-items: center;
    height: 30px;
  }

  &-underline,  {
    display: block;
    position: absolute;
    height: 2px;
    bottom: 5px;
    background-color: $accent-color;
    pointer-events: none;
    transition: all $transition-speed;
  }
}
