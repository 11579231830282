@import "../../../common/styles/colors";

.pricing__business-feature {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  width: 100%;

  &-descriptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-descriptions-heading {
    display: flex;
    flex-direction: column;
  }

  &-title {
    margin-bottom: 0;
  }

  &-image {
    width: 50%;
  }

  &-icon {
    color: #1FC5DB;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    & li {
      margin-bottom: 1rem;
      color: $gray-six;
      font-weight: $font-weight-light;
    }
  }
}

@media screen and (max-width: $tabletBreakPoint) {
  .pricing__business-feature {
    width: 100%;
    margin-bottom: 5rem;
    max-width: 60rem;
    flex-direction: column;

    &-image {
      width: 100%;
    }
  }
}