@import "table";
@import "../../../common/styles/colors";
@import "../../pricing-variables";

.pricing__comparison-table {
  &-data, &-data-title {
    width: $table-column-width;
  }

  &-check {
    fill: $secondary-color;
  }

  &-data {
    @include table-column;
    min-width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-title {
      background-color: transparent;
      font-weight: $font-weight-light;
      color: $gray-six;
      margin: 5px 0;

      &-section {
        margin-top: 50px;
        font-weight: $font-weight-bold;
      }
    }

    &-title:first-letter {
      text-transform: capitalize;
    }

    &-hover {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 1;
      transition: all $transition-speed;
    }

    &-hover:hover {
      background-color: rgba($color-white, 0.1);
    }
  }

  &-row {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
}