@import "../common/styles/_colors.scss";

$main-padding-vertical: 70px;

.pricing {
  &__container {
    width: 100vw;
    position: absolute;
    color: $color-white;
    background: transparent radial-gradient(circle at 50% 11%, #003842 0%, #000000 8%) 0 0 no-repeat padding-box;
    overflow-x: hidden;
  }

  &__heading {
    text-align: center;
    font-weight: $font-weight-light;
    font-size: 36px;
  }

  &__heading-main {
    font-size: 56px;
    width: 45rem;

    &-container-bottom {
      display: none;
    }
  }

  &__main {
    padding: $main-padding-vertical 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: $tabletBreakPoint) {
  .pricing {
    &__main {
      padding: $main-padding-vertical 10%;
    }

    &__heading-main {
      font-size: 2.5rem;
    }
  }
}

@media screen and (max-width: $mobileBreakPoint) {
  .pricing {
    &__container {
      background: $color-black;
    }

    &__main {
      padding: $main-padding-vertical 5%;
    }

    &__heading-main {
      font-size: 25px;
      width: auto;

      &-container-bottom {
        display: block;
        text-align: center;
        margin: 50px 0 20px 0;
      }
    }
  }
}
