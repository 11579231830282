@import "./table";
@import "../../../common/styles/colors";

.pricing__comparison-table {
  &-head-row, &-head-row-sub {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
  }

  &-head-row:first-letter,
  &-head-row-sub:first-letter,
  &-header-sub:first-letter {
    text-transform: capitalize;
  }

  &-head-row-sub .pricing__comparison-table-header-sub:first-child {
    margin-left: 0;
    margin-right: auto;
    background-color: transparent;
    width: auto;
    font-weight: $font-weight-bold;
  }

  &-header {
    border-radius: 12px 12px 0 0;
    padding-bottom: 15px;

    &-title {
      font-size: 28px;
      margin-bottom: 5px;

      &-sub {
        font-size: 14px;
        color: $color-text-light;
      }
    }
  }

  &-header, &-header-sub {
    @include table-column;
  }

  &-header-sub, &-header-title, & &-header-title-sub {
    font-weight: $font-weight-light;
  }

  &-header-sub {
    border-radius: 0;
    padding: 12px 0;
  }
}
