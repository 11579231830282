@import "../../common/styles/colors";

#mf {
    display: block;
    margin-top: 2rem;
}
.mf-row {
    margin-bottom: 1rem;
}

.mf-small-text {
    font-size: 1rem;
    font-weight: 300;
    color: #ededed;
    display: flex;
    justify-content: center;
    margin-bottom: 0.6rem;
}

.mf-spacer {
    height: 5rem;
}

.mf-header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 1.25rem;
    font-weight: 100;
    color: #ffffff;
    padding: 0.5rem;
    line-height: 0.7rem;

    strong {
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

.mf-header-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 100;
    color: #ffffff;
    padding: 0.5rem;
    line-height: 2rem;

    strong {
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1rem;
        padding: 0.75rem;
    }
}

.mf-title {
    font-size: 3rem;
    font-weight: 700;
    color: $color-white;
    display: block;
    text-align: center;
}

.mf-subtitle {
    font-size: 1.6rem;
    font-weight: 100;
    color: $color-white;
    display: block;
    text-align: center;
    padding: 0rem 2rem;
}

.mf-big-hero-message {
    font-size: 38px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
}
