@import "../../../common/styles/colors";
@import "../../pricing-variables";

$icon-size: 24px;
$icon-size-mobile: 24px;

.pricing__feature-title {
  display: flex;
  flex-direction: column;

  &-icon, &-icon-container {
    color: $secondary-color;
    width: $icon-size;
    height: $icon-size;
    font-size: $icon-size;
  }

  &-text {
    font-size: 20px;
    font-weight: $font-weight-normal;
    margin: 15px 0;
  }
}

@media screen and (max-width: $tabletBreakPoint) {
  .pricing__feature {
    &-title {
      flex-direction: row;
      gap: 15px;
      align-items: center;

      &-icon, &-icon-container {
        width: $icon-size-mobile;
        height: $icon-size-mobile;
        font-size: $icon-size-mobile;
      }
    }
  }
}
