@import "../../../common/styles/colors";
@import "../../pricing-variables";

.subscription__feature, .subscription__feature-example {
  display: flex;
}

.subscription__feature {
  justify-content: space-between;

  &-tooltip-mobile {
    display: none;
  }

  &-icon {
    color: $accent-color;
    margin-right: 10px;
  }

  &-text {
    margin-top: 2px;
    font-weight: $font-weight-light;
  }

  &-example:not(:last-child) {
    margin-bottom: 10px;
  }

  &-name:not(:last-child) {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: $tabletBreakPoint) {
  .subscription__feature {
    flex-direction: column;
    margin-top: 16px;

    &:not(:first-child) {
      margin-top: 30px;
    }

    &-name {
      align-self: flex-start;
    }

    &-text {
      color: #ccc;
    }

    &-tooltip {
      &-desktop {
        display: none;
      }

      &-mobile {
        width: 100%;
        display: block;
      }
    }
  }
}