@import "../../../common/styles/colors";
@import "../../pricing-variables";

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  cursor: default;
}

.tooltip .tooltip-text {
  opacity: 0;
  visibility: hidden;
  text-align: left;
  white-space: nowrap;
  background-color: #E6E6E6;
  color: black;
  padding: 15px;
  position: absolute;
  z-index: 1;
  left: 25px;
  transition: opacity $transition-speed, visibility $transition-speed;
}

.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent $gray-six transparent transparent;
}

.tooltip:hover .tooltip-text {
  opacity: 1;
  visibility: visible;
}

.tooltip:hover > svg {
  stroke: $accent-color;
}