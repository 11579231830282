@import "./table";

.pricing__comparison-table-body {
  overflow-y: scroll;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }
}
