@import "../../common/styles/colors";
@import "../pricing-variables";

.price-card {
  box-sizing: border-box;
  border: 2px solid #262626;
  border-radius: 12px;
  width: 350px;
  background-color: $color-black;
  padding: 30px;

  &--transparent {
    background-color: rgba($color-black, 0.4);
    border: 2px solid rgba($accent-color, 0.4);
  }

  &__title, &__subtitle, &__features-title {
    font-weight: $font-weight-light;
  }

  &__title {
    margin: 0;
    font-size: 28px;

    &-container {
      display: flex;
      align-items: baseline;
    }

    &-most-popular {
      margin-left: 10px;
      opacity: 1;
      color: #1FC5DB;
      transition: opacity $transition-speed;

      &--hidden {
        opacity: 0;
      }
    }
  }

  &__subtitle {
    font-size: 14px;
    margin: 0 0 25px 0;
  }

  &__subtitle, &__features-title {
    color: $gray-three;
  }

  &__price, &__period {
    font-size: 20px;
  }

  &__price-wrapper {
    transition: opacity $transition-speed;
    &--hidden {
      opacity: 0;
    }
  }

  &__price, &__unlimited-cases {
    color: $secondary-color;
  }

  &__features-title {
    height: 1rem;
  }

  .price-card__billed {
    &-annually, &-monthly {
      font-size: 14px;
      color: $gray-three;
      font-weight: $font-weight-light;
      transition: opacity $transition-speed, visibility $transition-speed;
    }

    &-annually {
      opacity: 1;
    }

    &-monthly {
      opacity: 0;
    }
  }
}

@media screen and (max-width: $tabletBreakPoint) {
  .price-card {
    width: 100%;
    margin-bottom: 2rem;

    &__features-title:empty {
      display: none;
    }

    &__features-title {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $mobileBreakPoint) {
  .price-card {
    width: 100%;
    max-width: unset;
    padding: 15px;
  }
}