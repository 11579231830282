@import "../../common/styles/colors";
@import "full-comparison/table";
@import "../pricing-variables";

.pricing__comparison {
  &-title {
    text-align: center;
    font-size: 36px;
    font-weight: $font-weight-light;
    margin-top: 0;
  }

  &-button {
    color: $secondary-color;
    border: 0;
    background-color: transparent;
    font-weight: $font-weight-light;
    font-size: 20px;

    &-container {
      text-align: center;
      margin-bottom: 50px;
    }
  }

  &-table {
    display: flex;
    flex-direction: column;
    border-collapse: collapse;
    height: 70%;
    flex-grow: 1;

    &-container {
      height: 75vh;
      padding: 50px;
      display: flex;
      flex-direction: column;
      width: 1150px;
    }

    &-under {
      position: relative;
      top: -3px;
      display: flex;
      justify-content: flex-end;

      &-item {
        @include table-column;
        height: 30px;
        border-radius: 0 0 12px 12px;
      }
    }
  }

  &-footer {
    margin-top: 20px;

    &-button {
      margin-right: 20px;
    }

    &-text {
      color: #ccc;
    }
  }
}

@media screen and (max-width: $tabletBreakPoint) {
  .pricing__comparison-button-container {
    display: none;
  }
}