@import "../../../common/styles/colors";

$table-column-width: 25%;

@mixin table-column {
  width: 25%;
  box-sizing: border-box;
  background-color: $color-black;
  text-align: center;
  margin-left: 20px;
}

