@import "../../common/styles/_colors.scss";
@import "../../common/styles/_mixin.scss";

.design-experience {
    padding: 3rem 0 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    align-items: center;
    overflow: hidden;
    border-top: 1px solid #333333;

    .de-main-image {
        width: 100%;
        height: auto;
        margin: 0 auto;
        background: transparent radial-gradient(closest-side at 50% 50%, #003842 0%, #00000000 80%) 0% 0% no-repeat padding-box;
        margin-top: -5px;
    }
}

.de-message-container {
    display: flex;
    flex-direction: column;
    color: $color-white;
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: 1rem;
    margin: 0 auto;
    padding: 0 1.7rem 1rem 1.7rem;

    .de-message-title {
        font-size: 1.625rem;
        font-weight: 700;
    }

    .de-message-body {
        padding-bottom: 0;

        p {
            margin: 1rem 0;
            font-size: 1rem;
            line-height: 1.5rem;
        }

        a {
            text-decoration: underline;
            color: #8BBCFF;
        }
    }
}

.de-full-width {
    display: block;
}

.de-row {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    max-width: 100vw;
}

.de-header {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    padding-top: 4rem;
    padding-bottom: 1rem;
    display: flex;
    margin: auto;
    max-width: 19rem;
    width: 100%;

    .de-title-icon {
        height: 1.6rem;
        width: auto;
        margin: 0;
        margin-right: 0.6rem;
        &.ea-bigger {
            height: 2.8rem;
            margin-top: -0.4rem;
        }
    }
}

.de-data-container-wrapper {
    display: flex;
    flex-direction: column;
}
.de-data-container {
    display: none;
    flex-direction: column;
    &.forMobile {
        display: flex;
    }
}

.metalabVideo {
    object-fit: cover;
    max-width: 100vw;
    cursor: pointer;
    width: 100%;
    &::-webkit-media-controls {
        display: none !important;
    }
}

.de-video-container {
    position: relative;
}

.de-video-control {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .de-video-control-icon {
        display: block;
        font-size: 2rem;
        color: white;

        &.playing {
            display: none;
        }
    }
}

.de-ready-print-image {
    width: 140px;
    margin: 0 auto;
    display: flex;
}

@media screen and (min-width: $ipad-width) {

    .de-ready-print-image {
        margin: 3rem 0;      
        width: 198px;
    }

    .design-experience {
        padding: 0;
        background: transparent radial-gradient(closest-side at 70% 50%, #003842 0%, #00000000 100%) 0% 0% no-repeat padding-box;
        border-top: none;

        margin-bottom: 15rem;

        .de-main-image {
            height: auto;
            width: 96%;
            margin: unset;
            margin-top: -5px;
        }
    }

    .de-message-container {
        margin-left: 3rem;
        padding-bottom: 2rem;
        align-items: flex-end;
        font-size: 1.5rem;
        width: 48vw;
        max-width: unset;
        padding-right: 2rem;

        .de-message-title {
            font-size: 42px;
            text-align: right;
            max-width: 22rem;
        }

        .de-message-body {
            max-width: 28rem;
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            p {
                margin: 1.5rem 0;
                font-size: 1.25rem;
                line-height: 2rem;
            }
        }
    }
    .de-row {
        margin-right: 1.7rem;
        margin-left: 1.7rem;
        flex-direction: row;
        padding: unset;
        border-top: none;
    }

    .de-header {
        display: none;

        .de-title-icon {
            margin-top: -0.4rem;
        }
    }

    .de-full-width {
        width: 100vw;
        margin-top: 2rem;
    }

    .de-data-container-wrapper {
        display: flex;
        flex-direction: column;
        width: 52vw;
    }
    .de-data-container {
        display: flex;
        flex-direction: column;
        width: 40rem;
        &.forMobile {
            display: none;
        }
    }

    .metalabVideo {
        object-fit: cover;
        max-width: 100vw;
        cursor: pointer;
        width: 100%;
        &::-webkit-media-controls {
            display: none !important;
        }
    }

    .de-video-container {
        position: relative;
    }

    .de-video-control {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .de-video-control-icon {
            display: none;
            font-size: 4rem;
            color: white;
        }

        &:hover {
            .de-video-control-icon {
                display: block;
            }
        }
    }
}
