@import "../../common/styles/_colors.scss";
@import "../../common/styles/_mixin.scss";

.metalab-guiding {
    padding: 3rem 0 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    align-items: center;
    overflow: hidden;
    padding-bottom: 3rem;

    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;

    &.mg-content {
        max-height: 41rem;
    }

    .mg-main-image {
        width: 100vw;
        height: auto;
        margin: 0 auto;
        background: transparent radial-gradient(closest-side at 50% 50%, #003842 0%, #00000000 80%) 0% 0% no-repeat padding-box;
    }
}

.mg-message-container {
    display: flex;
    flex-direction: column;
    color: $color-white;
    font-size: 1rem;
    font-weight: 300;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 1.7rem 1rem 1.7rem;

    .mg-message-title {
        font-size: 1.625rem;
        font-weight: 700;
    }

    .mg-message-body {
        padding-bottom: 0;

        p {
            margin: 1rem 0;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}

.mg-full-width {
    display: block;
}

.mg-row {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    padding-right: 1.7rem;
    padding-left: 1.7rem;
    position: relative;
}

.mg-header {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    padding-top: 4rem;
    padding-bottom: 1rem;
    display: flex;
    margin: auto;
    max-width: 19rem;
    width: 100%;

    .mg-title-icon {
        height: 1.6rem;
        width: auto;
        margin: 0;
        margin-right: 0.6rem;
        &.ea-bigger {
            height: 2.8rem;
            margin-top: -0.4rem;
        }
    }
}

.mg-ready-print-container {
    display: flex;
    margin-top: -50px;
    flex-direction: column;

    .mg-ready-print-title {
        font-size: 21px;
        font-weight: 300;
        color: white;
        margin-right: 1rem;
        padding: 0 1.7rem 0.5rem 1.7rem;
    }

    .mg-ready-print-image {
        height: 33px;
        width: 178px;
        margin-bottom: 0.6rem;
        padding: 0 1.7rem;
    }
}

@media screen and (min-width: $ipad-width) {
    .metalab-guiding {
        padding: 0;
        margin-bottom: 5rem;
        border-top: none;
        border-bottom: none;
        .mg-main-image {
            height: auto;
            width: 96%;
            margin: unset;
            margin-top: -5px;
        }
    }

    .mg-message-container {
        padding-bottom: 2rem;
        align-items: flex-start;
        font-size: 1.5rem;
        width: 48vw;
        max-width: unset;
        padding-right: 2rem;

        .mg-message-title {
            font-size: 42px;
            text-align: left;
            max-width: 48rem;
        }

        .mg-message-body {
            max-width: 28rem;
            text-align: left;
            p {
                margin: 1.5rem 0;
                font-size: 1.25rem;
                line-height: 2rem;
            }
        }
    }
    .mg-row {
        margin-right: 1.7rem;
        margin-left: 1.7rem;
        flex-direction: row;
        padding: unset;
        border-top: none;
    }

    .mg-header {
        display: none;

        .mg-title-icon {
            margin-top: -0.4rem;
        }
    }

    .mg-full-width {
        width: 100vw;
        margin-top: 2rem;
    }

    .mg-data-container-wrapper {
        display: flex;
        flex-direction: column;
        width: 52vw;
        align-items: flex-end;
    }
    .mg-data-container {
        display: flex;
        flex-direction: column;
        width: 40rem;
    }

    .metalabVideo {
        object-fit: cover;
        max-width: 100vw;
        cursor: pointer;
        width: 100%;
        &::-webkit-media-controls {
            display: none !important;
        }
    }

    .mg-video-container {
        position: relative;
    }

    .mg-video-control {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .mg-video-control-icon {
            display: none;
            font-size: 4rem;
            color: white;
        }

        &:hover {
            .mg-video-control-icon {
                display: block;
            }
        }
    }

    .mg-ready-print-container {
        display: flex;
        margin-top: -100px;
        align-items: flex-end;
        justify-content: center;
        flex-direction: row;

        .mg-ready-print-title {
            font-size: 30px;
            max-width: 274px;
            font-weight: 300;
            color: white;
            margin-right: 1rem;
            padding: 0 0 1rem 0;
        }

        .mg-ready-print-image {
            height: 37px;
            margin-bottom: 0.6rem;
        }
    }
}
