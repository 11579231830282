@import "../../../common/styles/colors";
@import "../../pricing-variables";

.pricing__standard-feature {
  width: 30%;
  margin-top: 30px;

  &-icon {
    color: $secondary-color;
  }

  &-title {
    font-size: 20px;
    font-weight: $font-weight-normal;
  }

  &-image {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .pricing__standard-feature {
    width: 45%;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: $tabletBreakPoint) {
  .pricing__standard-feature {
    width: 100%;
    margin-bottom: 2rem;
    max-width: 30rem;
  }
}